import { useEffect } from 'react';

export default function usePageviewConscoreScript() {

  useEffect(() => {
      const self: any = window;

      if (self.COMSCORE) {
        self.COMSCORE.beacon({
          c1: '2',
          c2: '20572948',
          options: {
            enableFirstPartyCookie: true,
            bypassUserConsentRequirementFor1PCookie: true,
          },
        });
      }
  }, []);
}
