import { useCallback, useEffect, useRef } from 'react';

export default function useBackground() {
  const PropagandaActive = useRef(false);
  const backgroundName = useRef('');

  const hasAdvertisingActive = useCallback((event: MessageEvent) => {
    if (typeof event.data === 'object' && 'background' in event.data) {
      backgroundName.current = event.data.background;

      if (backgroundName.current === 'bg-samsung-linhaz-popup') {
        const externalAdScript = document.createElement('script');
        externalAdScript.src = 'https://assets.canaltech.com.br/js/11d2412/samsung.js';
        externalAdScript.async = true;
        document.body.insertBefore(externalAdScript, document.body.firstChild);
      }

      PropagandaActive.current = true;
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', hasAdvertisingActive, false);

    return () => {
      window.removeEventListener('message', hasAdvertisingActive, true);
    };
  }, [hasAdvertisingActive]);

  return {
    isAdActive: PropagandaActive.current,
    adName: backgroundName.current,
  };
}
