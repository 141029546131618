import { getCurrentHub } from '@sentry/nextjs';
import classNames from '@/utils/className';

interface GoogleAdsTypes {
  Ads: boolean;
  whiteBg?: boolean;
  center?: boolean;
  shouldHaveAds?: boolean;
}

export default function Propaganda({ Ads, whiteBg, center, shouldHaveAds = true }: GoogleAdsTypes) {
  const variants = {
    TopoContainer: {
      center: {
        true: 'center [&.center]:h-[initial]',
        false: '',
      },
      whiteBg: {
        true: 'whiteBg [&.whiteBg]:bg-mglGray50',
        false: '',
      },
    },
  } as const;
  const currentSpan = getCurrentHub().getScope()?.getSpan();
  const topSpan = currentSpan && currentSpan.startChild({ op: 'top' });

  return !Ads ? (
    <div
      className={classNames(
        'flex h-[100px] flex-col items-center justify-center bg-mglGray100 desktop:h-[250px]',
        whiteBg != undefined && variants.TopoContainer.whiteBg[whiteBg.toString()],
        center != undefined && variants.TopoContainer.center[center.toString()]
      )}>
      <div className="flex h-auto w-full items-center justify-center text-sm uppercase text-mglGray500">{shouldHaveAds ? 'Publicidade' : ''}</div>
      <>{topSpan && topSpan.finish()}</>
    </div>
  ) : null;
}
