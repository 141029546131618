import { Dispatch, SetStateAction, useEffect, useRef } from 'react';

export default function useOutsideClickRef({ setIsOpen }: { setIsOpen: Dispatch<SetStateAction<boolean>> }) {
  const ref = useRef(null);

  const handleClickOutside = event => {
    if (!ref?.current?.contains(event?.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => document.removeEventListener('click', handleClickOutside, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return ref;
}
