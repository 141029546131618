import getEnv from '@/utils/getEnv';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

export default function usePageviewConscoreRequest() {
  const publicUrl = getEnv('URL');
  const randomHash = Math.random().toString(36).substring(7);
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', handlerPageViewComscoreRequest);
    return () => {
      router.events.off('routeChangeComplete', handlerPageViewComscoreRequest);
    };
  }, []);
  const handlerPageViewComscoreRequest = () => {
    if (typeof window !== 'undefined') {
      fetch(`${publicUrl}/pageview_candidate.txt?pr=${randomHash}`, { method: 'GET' }).then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      });
    }
  };
}
