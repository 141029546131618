import classNames from '@/utils/className';
import { ComponentPropsWithRef, ComponentPropsWithoutRef, forwardRef } from 'react';

const variants = {
  FormModalSelectMenu: {
    isOpen: {
      true: 'border-b-0 rounded-b-none cursor-default relative',
      false: '',
    },
  },
  FormModalSelectOptions: {
    isOpen: {
      true: 'bg-mglGray50 w-full block visible max-h-[148px] overflow-y-scroll',
      false: 'invisible hidden',
    },
  },
  FormModalSuccessMessage: {
    title: {
      true: 'text-2xl font-medium mb-2',
      false: '',
    },
  },
} as const;

export const FormModalFormComponent = ({ children, ...rest }: ComponentPropsWithoutRef<'form'>) => {
  return (
    <form className="mx-auto my-0 flex max-w-[83.33%] flex-col gap-y-6" {...rest}>
      {children}
    </form>
  );
};

export const FormModalFieldBox = ({ children, ...rest }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className="flex w-full flex-col gap-y-2" {...rest}>
      {children}
    </div>
  );
};

export const FormModalFieldName = ({ children, ...rest }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className="flex items-center gap-x-4" {...rest}>
      {children}
    </div>
  );
};

export const FormModalFieldLabel = ({ children, ...rest }: ComponentPropsWithoutRef<'label'>) => {
  return (
    <label className="text-xs text-mglGray900" {...rest}>
      {children}
    </label>
  );
};

export const FormModalTextInput = ({ ...rest }: ComponentPropsWithoutRef<'input'>) => {
  return (
    <input
      className="w-full rounded border border-solid border-mglGray200 bg-mglGray50 p-3 text-[0.625rem] text-mglGray900 transition-all duration-300 ease-ease focus:border focus:border-solid focus:border-mglBlue400"
      {...rest}
    />
  );
};

export const FormModalTextArea = ({ ...rest }: ComponentPropsWithoutRef<'textarea'>) => {
  return (
    <textarea
      className="min-h-[100px] w-full rounded border border-solid border-mglGray200 bg-mglGray50 p-3 text-[0.625rem] text-mglGray900 transition-all duration-300 ease-ease focus:border focus:border-solid focus:border-mglBlue400"
      {...rest}
    />
  );
};

export const FormModalSelect = forwardRef<HTMLDivElement, ComponentPropsWithRef<'div'>>(({ children, ...rest }, ref) => {
  return (
    <div ref={ref} className="relative" {...rest}>
      {children}
    </div>
  );
});

export const FormModalSelectText = ({ children, ...rest }: ComponentPropsWithoutRef<'p'>) => {
  return (
    <p className="text-[0.625rem] text-mglGray900" {...rest}>
      {children}
    </p>
  );
};

export const FormModalSelectMenu = ({ children, isOpen, ...rest }: ComponentPropsWithoutRef<'div'> & { isOpen?: boolean }) => {
  return (
    <div
      className={classNames(
        'relative w-full cursor-pointer appearance-none rounded border border-solid border-mglGray200 bg-mglGray50 bg-[url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E")] bg-[length:0.6em] bg-[calc(100%-1.3em)_center] bg-no-repeat p-3 focus:border-mglBlue400',
        isOpen != undefined && variants.FormModalSelectMenu.isOpen[isOpen.toString()]
      )}
      {...rest}>
      {children}
    </div>
  );
};

export const FormModalSelectOptions = ({ children, isOpen, ...rest }: ComponentPropsWithoutRef<'div'> & { isOpen?: boolean }) => {
  return (
    <div
      className={classNames(
        'absolute z-[1] border border-t-0 border-solid border-mglGray200 transition-all duration-75 ease-ease',
        isOpen != undefined && variants.FormModalSelectOptions.isOpen[isOpen.toString()]
      )}
      {...rest}>
      {children}
    </div>
  );
};
export const FormModalSelectInput = ({ ...rest }: ComponentPropsWithoutRef<'input'>) => {
  return <input className="hidden" {...rest} />;
};

export const FormModalSelectLabel = ({ children, ...rest }: ComponentPropsWithoutRef<'label'>) => {
  return (
    <label
      className="block cursor-pointer p-2 text-[0.625rem] transition-all duration-300 ease-ease hover:bg-mglBlue400 hover:text-mglGray50 focus:bg-mglBlue400 focus:text-mglGray50"
      {...rest}>
      {children}
    </label>
  );
};

export const FormModalOption = ({ children, ...rest }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div
      className="w-full bg-mglGray50 p-2 text-[0.625rem] text-mglGray900 transition-all duration-300 ease-ease hover:border hover:border-solid hover:border-mglBlue400"
      {...rest}>
      {children}
    </div>
  );
};

export const FormModalSubmitButton = ({ children, className = '', ...rest }: ComponentPropsWithoutRef<'button'>) => {
  return (
    <button
      className={classNames(
        'max-w-80 rounded-[60px] border-none [background:0] [&>div>div>span]:text-base [&>div>div>span]:leading-4 [&>div>div]:me-[auto] [&>div>div]:ms-[auto] [&>div>span>svg]:disabled:hidden [&>div]:flex [&>div]:border-black/10 [&>div]:px-6 hover:[&>div]:bg-mglGray900 hover:[&>div]:text-mglGray50 [&>div]:disabled:opacity-40 [&>div]:disabled:hover:cursor-default [&>div]:disabled:hover:border [&>div]:disabled:hover:border-solid [&>div]:disabled:hover:border-black/5 [&>div]:disabled:hover:bg-mglGray50 [&>div]:disabled:hover:text-mglGray900 [&>span]:m-0',
        className
      )}
      {...rest}>
      {children}
    </button>
  );
};

export const FormModalSuccessMessageContainer = ({ children, ...rest }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className="flex flex-col items-center justify-center" {...rest}>
      {children}
    </div>
  );
};

export const FormModalSuccessMessage = ({ children, isTitle, ...rest }: ComponentPropsWithoutRef<'p'> & { isTitle?: boolean }) => {
  return (
    <p className={classNames('text-base text-mglGray900', isTitle != undefined && variants.FormModalSuccessMessage[isTitle.toString()])} {...rest}>
      {children}
    </p>
  );
};

export default {
  FormModalFieldBox,
  FormModalFieldName,
  FormModalFieldLabel,
  FormModalFormComponent,
  FormModalTextInput,
  FormModalTextArea,
  FormModalSelect,
  FormModalSelectText,
  FormModalSelectMenu,
  FormModalSelectOptions,
  FormModalSelectInput,
  FormModalSelectLabel,
  FormModalOption,
  FormModalSubmitButton,
};
