import Chip from '../Chip';
import classNames from '@/utils/className';

interface TituloProps {
  texto: string;
  urlRedirect?: string;
  showChip?: boolean;
  alignRight?: boolean;
  color: 'white' | 'black';
  headingTag?: 'h1' | 'h2';
  noPadding?: boolean;
}

export default function Titulo({ texto, color, urlRedirect, showChip, alignRight, headingTag = 'h2', noPadding = false }: TituloProps) {
  const titleStyles = 'flex items-center text-2xl text-left font-medium';
  const Heading = headingTag;

  const chipProps = {
    black: {
      color: 'black',
      border: 'black',
      title: 'text-mglGray900',
      hover: 'black',
    },
    white: {
      color: 'white',
      border: 'white',
      title: 'text-mglGray50',
      hover: 'white',
    },
  } as const;

  return (
    <div
      className={classNames('flex items-center pb-2 [&>svg]:ml-[5px] [&>svg]:transition-all [&>svg]:duration-200 hover:[&>svg]:ml-[10px]', noPadding && 'p-0')}>
      <Heading className={classNames(titleStyles, !alignRight && 'mr-2', chipProps[color].title)}>{texto}</Heading>
      {alignRight && <div className="mr-auto" />}
      {showChip && (
        <Chip
          texto="ver tudo"
          color={chipProps[color].color}
          chipSize="medium"
          posIcon="chevron-right"
          title={`ver tudo - ${texto}`}
          borderColor={chipProps[color].border}
          link={urlRedirect}
          hover={chipProps[color].hover}
        />
      )}
    </div>
  );
}
