import useBreakpoint from '@/hooks/useBreakpoint';
import useScriptFlag from '@/hooks/useScriptFlag';
import { useEffect, useRef } from 'react';

// @refresh reset
export function CazambaAds() {
  const [isMobile] = useBreakpoint('phone');

  const hasRendered = useRef(false);
  const shouldRenderAds = useScriptFlag({ adFlag: 'cazamba' });

  const appendScript = () => {
    if (hasRendered.current) return;

    const existingComponent = document.getElementById('cazambaPos');
    if (!existingComponent) return;

    const existingScript = document.getElementById('cazamba');
    if (existingScript) return;

    const script = document.createElement('script');
    script.id = 'cazamba';
    script.setAttribute('cfasync', 'false');
    script.setAttribute('delayedSrc', 'https://api.cazamba.com/367defddd45f62f92c247bb37f37f181.js');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;

    const cazambaParent = document.getElementById('cazambaPos');
    cazambaParent.appendChild(script);

    hasRendered.current = true;

    setTimeout(() => {
      script.setAttribute('src', script.getAttribute('delayedSrc'));
    }, 5000);
  };

  useEffect(() => {
    if (shouldRenderAds) appendScript();
  }, []);

  if (isMobile) return null;

  return <div id="cazambaPos" className="cazambaPos" />;
}
