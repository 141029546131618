/* eslint-disable react/jsx-props-no-spreading */
import React, { ImgHTMLAttributes, useState } from 'react';
import { BlurhashCanvas } from 'react-blurhash';
import LazyLoad from 'react-lazyload';
import { getCurrentHub } from '@sentry/nextjs';
import ContentComposer, { ComponentProps } from '../../Internals/ContentComposer';
import * as S from './styles';
import classNames from '@/utils/className';

export interface CustomImageComponent extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  blurhash?: string;
  width?: number;
  height?: number;
  alt?: string;
  figcaption?: string | string[] | ComponentProps[];
  srcset?: { [key: number]: string };
  editorialCategory?: 'ctup' | 'eletro' | 'ctauto';
  isHeader?: 'internal' | 'full';
  objectFit?: 'object-cover' | 'object-contain' | 'object-fill' | 'object-none';
}

export default function ImageComponent({
  src,
  blurhash = undefined,
  alt,
  title = undefined,
  figcaption = undefined,
  srcset = undefined,
  width = undefined,
  height = undefined,
  objectFit = 'object-cover',
}: CustomImageComponent) {
  const currentSpan = getCurrentHub().getScope()?.getSpan();
  const imageComponentSpan = currentSpan && currentSpan.startChild({ op: 'imageComponent', description: src });

  const [hasLoaded, setHasLoaded] = useState(false);
  return (
    <S.ContainerImage style={{ width: width || '100%', height: height || '100%' }}>
      <LazyLoad className="h-full rounded" height={height || 1}>
        <img
          src={src}
          srcSet={`${srcset && typeof srcset !== 'undefined' ? Object.keys(srcset).map(e => `${srcset[e]} ${e}w `) : ''}`}
          sizes={`${srcset && typeof srcset !== 'undefined' ? Object.keys(srcset).map(e => `(max-width: ${e}px) ${e}px `) : ''}`}
          alt={alt}
          title={title || alt}
          onLoad={() => setHasLoaded(true)}
          width={width && width}
          height={height && height}
          style={!hasLoaded ? { display: 'none' } : null}
          className={objectFit}
        />
        {!hasLoaded && blurhash && <BlurhashCanvas hash={blurhash} width={width || 64} height={height || 36} punch={1} />}

        {figcaption && (
          <S.CaptionImage>
            {figcaption && figcaption !== undefined && figcaption.length > 0 && typeof figcaption === 'string'
              ? figcaption
              : Array.isArray(figcaption) &&
                figcaption?.map((figcapitem, id) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={`${typeof figcapitem === 'string' ? figcapitem : figcapitem.valor}-${id}`}>
                    {figcapitem && typeof figcapitem === 'string' && figcapitem?.length > 0
                      ? figcapitem.charAt(0).toUpperCase() + figcapitem.slice(1)
                      : typeof figcapitem === 'object' && (
                          <ContentComposer
                            tipo={figcapitem?.tipo}
                            url={figcapitem.url}
                            as={figcapitem?.tag}
                            valor={figcapitem?.valor}
                            target={figcapitem.target}
                          />
                        )}
                  </React.Fragment>
                ))}
          </S.CaptionImage>
        )}
        {imageComponentSpan && imageComponentSpan.finish()}
      </LazyLoad>
    </S.ContainerImage>
  );
}
