/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/no-array-index-key */
import Head from 'next/head';
import { HeadTags } from '@/shared/types/home.types';
import { useAmp } from 'next/amp';
import { Fragment, createElement } from 'react';

interface HeadProps {
  headPage: HeadTags[];
}

export default function HeadersPage({ headPage }: HeadProps) {
  const isAmp = useAmp();
  const findCategory = () => {
    if (isAmp) {
      const category = headPage.find(page => page?.attr?.property === 'article:section')?.attr?.content ?? 'Análise';
      return category;
    }
    return '';
  };

  return (
    <Head>
      <meta name="author" content="Canaltech" />
      {headPage &&
        headPage.map((page, index) => {
          const shouldFilter = () => {
            if (isAmp) {
              if (page.tag === 'meta') {
                return (page.attr.hasOwnProperty('name') && page.attr.name === 'revisit-after') || page.attr.hasOwnProperty('http-equiv');
              }
            }
            return false;
          };

          if (shouldFilter()) return <Fragment key={index} />;
          return (
            <Fragment key={index}>
              {page.tag === 'title'
                ? createElement(page.tag, { key: index, ...page.attr }, isAmp ? page.content.replace(' - Canaltech', ` - ${findCategory()}`) : page.content)
                : createElement(page.tag, { key: index, ...page.attr })}
              {page.tag === 'meta' &&
                page.attr.hasOwnProperty('http-equiv') &&
                createElement(page.tag, { key: index, httpEquiv: 'Cache-control', content: page.attr.content })}
            </Fragment>
          );
        })}
    </Head>
  );
}
